import React from "react";
import {
  Box,
  Text,
  Center,
  HStack,
  Grid,
  Image,
  GridItem,
  Flex,
  SimpleGrid,
  AspectRatio,
  VStack,
} from '@chakra-ui/react';
import { FormattedMessage } from "gatsby-plugin-react-intl"

function Earn() {
  const data = [
    {
      icon: ["xtr"],
      title: "",
      content: "",
    },
    {
      icon: ["xtr", "gxt"],
      title: "",
      content: "",
    },
    {
      icon: ["gxt"],
      title: "",
      content: "",
    },
    {
      icon: ["dollar"],
      title: "",
      content: "",
    }
  ]
  return (
    <Box
      mx={[1, 2, 4]}
      rounded="24px"
      bg="#132035"
      color="white"
      py={16}
      px={[4, 16]}
    >
      <Center >
        <Text fontSize={{ base: '20px', md: '60px' }} fontWeight="900" mb={16}>
          <FormattedMessage id="litepaper.earn_title" />
        </Text>
      </Center>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gap={12} gridAutoRows="1fr">
        {data.map((item, index) => (


          <AspectRatio ratio={{ base: 1096 / 1070, md: 2080 / 757 }}>
            <Flex position="relative"
              bgImage={{ base: "/litepaper/earn/card-mb.webp", md: "/litepaper/earn/card.webp" }}
              bgSize='contain'
              alignItems="flex-start !important"
              justifyContent="flex-start !important"
              flexDirection={{ base: "column" }}
              p={4}
            >
              <HStack justifyContent="flex-start" alignItems="flex-start">
                {item.icon.map(icon => (
                  <Image
                    maxH={[8, 12]}
                    objectFit='contain'
                    src={`/litepaper/earn/${icon}.webp`}
                    alt='xtr'
                    mr={2}
                  />
                ))}
              </HStack>
              <Text fontSize={[20, 24]} fontWeight="700" my={2} >
                <FormattedMessage id={`litepaper.earn_subtitle_${index + 1}`} />
              </Text>
              <Text fontSize={[16, 20]}>
                <FormattedMessage id={`litepaper.earn_des_${index + 1}`} />
              </Text>
            </Flex>
          </AspectRatio>

        ))}

      </SimpleGrid>

    </Box >
  );
}

export default Earn;
