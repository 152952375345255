import React from "react";
import {
  Box,
  Text,
  Center,
  HStack,
  Grid,
  Image,
} from '@chakra-ui/react';
import { FormattedMessage } from "gatsby-plugin-react-intl"

function Burn() {
  const data = [
    {
      isBoth: false,
    }, {
      isBoth: false,
    }
    , {
      isBoth: false,
    }, {
      isBoth: true,
    }, {
      isBoth: true,
    }, {
      isBoth: true,
    }, {
      isBoth: true,
    }
  ]
  return (
    <Box
      py={16}
      color="#fff"
      px={[8, 16, 32]}>
      <Text fontSize={{ base: '20px', md: '60px' }} textAlign="center" fontWeight="900" mb={16}>
        <FormattedMessage id="litepaper.burn_title" />
      </Text>
      {data.map((item, index) => (
        <Grid
          bg="#132035"
          templateColumns={{ base: '1fr', md: '1fr 4fr' }} border="3px solid #EEEEEE" rounded="21px" boxShadow={"3px 4px 4px 2px rgba(192, 192, 192, 0.25)"} mb={12} gap={6} p={8}>
          <HStack >
            <Image
              w="40%"
              maxW={{ base: '40px', xl: "80px" }}
              objectFit='contain'
              src='/litepaper/xtr.png'
              alt='xtr'
              mr={8}
            />
            {item.isBoth && <Image
              w="40%"
              maxW={{ base: '40px', xl: "80px" }}
              objectFit='contain'
              src='/litepaper/gxt.png'
              alt='xtr'
            />}
          </HStack>
          <Box flexBasis={{ base: '100%', md: "80%" }} style={{ marginInlineStart: "0 !important" }}>
            <Text fontSize={{ base: '24px', md: '40px' }} fontWeight="700">
              <FormattedMessage id={`litepaper.sub_subtitle_${index + 1}`} />
            </Text>
          </Box>
        </Grid>
      ))}
    </Box>
  );
}

export default Burn;
