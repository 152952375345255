import React from "react";
import {
  Box,
  Text,
  VStack,
  SimpleGrid,
  Center,
  HStack,
  Heading,
  Grid,
  Image,
  Flex,
  GridItem, AspectRatio
} from '@chakra-ui/react';
import { FormattedMessage } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import useLanguage from "../hooks/useLanguage";
import What from "../containers/litepaper/what";
import Burn from "../containers/litepaper/burn";
import Easy from "../containers/litepaper/easy";
import Earn from "../containers/litepaper/earn";

function Litepaper() {
  const { lang } = useLanguage()
  return (
    <Layout>
      <Box mt="80px">
        <Box bgColor="#0C1317">
          <Image
            boxSize='100%'
            objectFit='contain'
            display={{ base: 'block', md: 'none' }}
            src='/litepaper/banner_mb.png'
            alt='banner_mb'
          />
          <Image
            boxSize='100%'
            objectFit='contain'
            display={{ base: 'none', md: 'block' }}
            src='/litepaper/banner.png'
            alt='banner'
          />
        </Box>
        <What />
        <Easy />
        <Box
          px={[4, 8, 16, 32]}
          py={16}
          mx={[2, 4]}
          mt={8}
          rounded="24px"
          // bgGradient="linear-gradient(180deg, #0C1317 0%, #063A45 100%)"
          bg="#132035"
        >
          <SimpleGrid columns={[1, 1, 2]} textColor="#fff" gap={6}>
            <Box>
              <HStack alignItems="flex-start">
                <Image
                  maxH={{ base: "90px", md: "120px" }}
                  objectFit='contain'
                  src='/litepaper/xtr.png'
                  alt='xtr'
                  mr={4}
                />
                <VStack alignItems="flex-start" justifyContent="flex-start" >
                  <Text fontSize="40px" fontWeight="900" lineHeight="36px" >
                    XTR
                  </Text >
                  <Text fontSize="36px" >
                    <FormattedMessage id="litepaper.xtr_des" />
                  </Text>
                </VStack>
              </HStack>
            </Box>
            <Box>
              <HStack alignItems="flex-start">
                <Image
                  maxH={{ base: "90px", md: "120px" }}
                  objectFit='contain'
                  src='/litepaper/gxt.png'
                  alt='xtr'
                  mr={4}
                />
                <VStack alignItems="flex-start" justifyContent="flex-start" >
                  <Text fontSize="40px" fontWeight="900" lineHeight="36px" >
                    GXT
                  </Text >
                  <Text fontSize="36px" color="#F5AC3C">
                    <FormattedMessage id="litepaper.gxt_des" />
                  </Text>
                </VStack>
              </HStack>
            </Box>
          </SimpleGrid>
          <Image
            boxSize='100%'
            objectFit='contain'
            src='/litepaper/line.png'
            alt='line'
            my={4}
          />
          <SimpleGrid columns={{ base: 1, md: 2 }} textColor="#fff" mt={8}>
            <GridItem colStart={1} colEnd={1} rowStart={{ base: 2, md: 1 }} rowEnd={{ base: 2, md: 1 }} justifyContent={{ base: "center", md: "flex-end" }}>
              <VStack alignItems={{ base: "center", md: "flex-start" }} justifyContent="center" height='full'>
                <Text fontSize={{ base: "48px", md: "64px" }} fontWeight="900"  >
                  <FormattedMessage id="litepaper.xtr" />
                </Text >
                <Text fontSize={{ base: "48px", md: "64px" }} fontWeight="900" color="#7E7E7E" pl={{ base: 0, md: 32 }}>
                  &
                </Text >
                <Text fontSize={{ base: "48px", md: "64px" }} fontWeight="900" color="#F5AC3C" textAlign={{ base: "center", md: "left" }}>
                  <FormattedMessage id="litepaper.gxt" />
                </Text>
              </VStack>
            </GridItem>
            <GridItem colStart={{ base: 1, md: 2 }} colEnd={{ base: 1, md: 2 }} rowStart={1} rowEnd={1} w='full'>
              <Image
                boxSize='100%'
                objectFit='contain'
                src='/litepaper/coin.png'
                alt='xtr'
                mr={4}
              />
            </GridItem>
          </SimpleGrid>
        </Box>
        <Image
          boxSize={['100%', '80%', '60%']}
          objectFit='contain'
          src={`/litepaper/market/market_${lang}.webp`}
          alt='market'
          my={32}
          mx="auto"
        />
        <Earn/>
        <Burn />
      </Box >
    </Layout >
  );
}

export default Litepaper;

export const Head = () => (
  <>
    <title>XTRIP LITEPAPER - WHAT IS XTRIP? (Travel and Earn "Xtrip" Lite paper)</title>
    <meta name="description" content={`XTRIP LITEPAPER - WHAT IS XTRIP? (Travel and Earn "Xtrip" Lite paper)`} />
    <meta property="og:image" content="/litepaper/meta-bg.webp" />
  </>
)