import React from "react";
import {
  Box,
  Text,
  Center,
  Heading,
  Grid,
  AspectRatio
} from '@chakra-ui/react';
import { FormattedMessage } from "gatsby-plugin-react-intl"

function Easy() {
  return (
    <Box
      color="#fff"
      pt={0}
      px={[8, 16, 32]}
    >
      <AspectRatio ratio={16 / 9} borderRadius="24px" zIndex={1} overflow="hidden" mb={{ base: '40px', md: "80px" }}>
        <iframe
          src="https://www.youtube.com/embed/Vs4xb3RNvIA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </AspectRatio>
      <Text fontSize={{ base: '20px', md: '60px' }} fontWeight="900" mb={16} textTransform="uppercase">
        <Center>
          <FormattedMessage id="litepaper.sec2_title_1" />
        </Center>
      </Text>
      <Grid templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} minH={"45vh"} gap={8} mb={{ base: '40px', md: "80px" }}>
        <Box>
          <Heading fontSize={{ base: '16px', md: '40px' }} fontWeight="700" mb={4}>
            <FormattedMessage id="litepaper.sec2_title_2" />
          </Heading>
          <Text>
            <FormattedMessage id="litepaper.sec2_subtitle_2" /><br />
            <FormattedMessage id="litepaper.sec2_subtitle_2_2" /><br />
            <FormattedMessage id="litepaper.sec2_subtitle_2_3" />
          </Text>
        </Box>
        <Box ml={{ base: 0, md: 16 }}>
          <Heading fontSize={{ base: '16px', md: '40px' }} fontWeight="700" mb={8}>
            <FormattedMessage id="litepaper.sec2_title_3" />
          </Heading>
          <Text>
            <FormattedMessage id="litepaper.sec2_subtitle_3" />
          </Text>
        </Box>
        <Box>
          <Heading fontSize={{ base: '16px', md: '40px' }} fontWeight="700" mb={8}>
            <FormattedMessage id="litepaper.sec2_title_4" />
          </Heading>
          <Text>
            <FormattedMessage id="litepaper.sec2_subtitle_4" />
          </Text>
        </Box>
        <Box ml={{ base: 0, md: 16 }}>
          <Heading fontSize={{ base: '16px', md: '40px' }} fontWeight="700" mb={8}>
            <FormattedMessage id="litepaper.sec2_title_5" />
          </Heading>
          <Text>
            <FormattedMessage id="litepaper.sec2_subtitle_5" /><br />
            <FormattedMessage id="litepaper.sec2_subtitle_5_2" />
          </Text>
        </Box>
      </Grid>

    </Box>
  );
}

export default Easy;

