import React from "react";
import {
  Box,
  Text,
  VStack,
  Center,
  Heading,
  Image,
  Flex,
} from '@chakra-ui/react';
import { FormattedMessage } from "gatsby-plugin-react-intl"
function What() {
  return (
    <Box
      color="#fff"
      py={16}
      px={[8, 16, 32]}
    >
      <Flex
        bgImage={{ base: "/", md: "/litepaper/bn_section2.png" }}
        bgPosition="right center"
        bgRepeat="no-repeat"
        bgSize="contain"
        height="100%"
        flexWrap="wrap"
        color="#fff"
        minH={"70vh"}
      >
        <Image src="/litepaper/bg2_section2.png"
          boxSize='100%'
          objectFit='contain'
          display={{ base: "block", md: "none" }}
        />
        <Center flexBasis={{ base: "100%", md: "35%" }} >
          <VStack alignItems="flex-start">
            <Text fontSize={{ base: '20px', md: '60px' }} textAlign={"left"} mb={4} fontWeight="900">
              <FormattedMessage id="litepaper.sec1_title" />

            </Text>
            <Text lineHeight="30px">
              <FormattedMessage id="litepaper.sec1_subtitle_1" /> <br />
              <FormattedMessage id="litepaper.sec1_subtitle_2" /> <br />
              <FormattedMessage id="litepaper.sec1_subtitle_3" /> <br />
              <FormattedMessage id="litepaper.sec1_subtitle_4" /> <br />
            </Text>
          </VStack>
        </Center>
      </Flex>
    </Box>
  );
}

export default What;
